<script lang="ts" setup>
import type { PageCollection, Games } from "@/types";

const { open } = useNlcModals();
const { data: collections } = useGetPageCollectionsData();
const loginGuard = useLoginGuard();

const openGamesModal = (collection: PageCollection) => {
	open("LazyOModalCollectionsGames", { title: collection.title, collectionSlug: collection.slug });
};
const handleNavigateToPage = () => {
	loginGuard({
		success: () => navigateTo("/collections"),
		fail: () => open("LazyOModalSignup")
	});
};
</script>

<template>
	<MHomeSectionWrapper
		title="Collections"
		icon="24/collections"
		showAll
		data-tid="collections"
		@view-all-action="handleNavigateToPage"
	>
		<template v-if="!collections?.length">
			<AAnimationSkeleton v-for="i in 4" :key="i">
				<ASkeleton width="332px" height="402px" />
			</AAnimationSkeleton>
		</template>
		<template v-else>
			<MCollectionCard
				v-for="(item, index) in collections"
				:key="index"
				:title="item.title"
				:logo="item.logo"
				:games="item.games as Games"
				:data-tid="`collection-${item.slug}`"
				:slug="item.slug"
				@click="openGamesModal(item)"
			/>
		</template>
	</MHomeSectionWrapper>
</template>

<style />
